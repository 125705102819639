import React, { useState, memo } from 'react';
import { Card, CardContent, Typography, TextField, Divider, Box, InputAdornment } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const CustomPaymentOptions = (options, paymentType, customPaymentContract) => {
   // console.log('options,paymentType.paymentType: ',  options.paymentType );

  const [monthsBefore, setMonthsBefore] = useState(1);
  const [amountBefore, setAmountBefore] = useState(100);
  const [monthsAfter, setMonthsAfter] = useState(1);
  const [amountAfter, setAmountAfter] = useState(100);
  const [amountOneTime, setAmountOneTime] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [selectOptions, setSelectOptions] = useState(options);
  const [selectPaymentType, setSelectPaymentType] = useState(options,paymentType.paymentType);

  const [customPaymentContractData, setCustomPaymentContractData] = useState({
    registrationfee: 0, //Ilk kayit ucreti
    paymentfirstdetails: {  // Pesin odemede bu alandaki cashamount degeri dolu gelir Installmentda ise bu alan sifir gelir taksit ay ve odeme bilgisi dolu gelir.
      installmentcount: monthsBefore,
      installmentamount: amountBefore,
      cashamount:  cashAmount,
      totalfirstamount: (monthsBefore * amountBefore)
    },
    paymentseconddetails:{  //Ise basladiktan sonraki secimi ifade etmektedir.      
      installmentcount1: monthsAfter,
      installmentamount1: amountAfter,
      totalsecondamount: (monthsAfter * amountAfter), 
      description: ''
    },   
  });

  
  const handleMonthsChangeBefore = (delta) => {
    setMonthsBefore((prev) => {
        const newValue = prev + delta;
        return Math.max(1, Math.min(newValue, 24));
      });
  };

  const handleMonthsChangeAfter = (delta) => {
    setMonthsAfter((prev) => {
        const newValue = prev + delta;
        return Math.max(1, Math.min(newValue, 24));
      });
  };

  const handleAmountChangeBefore = (delta) => {   
    setAmountBefore((prev) => {
        const newValue = prev + delta;
        return Math.max(100, Math.min(newValue, 1000));
      });
  };

  const handleAmountChangeAfter = (delta) => {
    setAmountAfter((prev) => {
        const newValue = prev + delta;
        return Math.max(100, Math.min(newValue, 1000));
      });
  };

  const handleAmountChangeOneTime = (delta) => {
    setAmountOneTime((prev) => {
        const newValue = prev + delta;
        // return Math.max(100, Math.min(newValue, 1000));
        return newValue; //TODO: Burada min deger hazirlanacak mi?
      });
  };

//   const handleChangeCashAmount = (event) => {
//     setcashAmount(event.target.value);
//   };

  const handleChangeCashAmount = (event) => {
    const value = event.target.value;
    //console.log('value', value);
    const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value === '' || regex.test(value)) {
      setCashAmount(value);
    }
  };

  const handleBlurCashAmount = () => {
    if (cashAmount !== '') {
      setCashAmount(parseFloat(cashAmount).toFixed(2));
    }
  };

  const handleChangeOneTimeAmount = (event) => {
    const value = event.target.value;
    //console.log('value', value);
    const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value === '' || regex.test(value)) {
        setAmountOneTime(value);
    }
  };

  const handleBlurOneTimeAmount = () => {
    if (amountOneTime !== '') {
        setAmountOneTime(parseFloat(amountOneTime).toFixed(2));
    }
  };

  return (
    <>
        { options.paymentType === 'INSTALLMENT' & selectOptions.options === 'CustomOptions'  ? (
            <Card sx={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ mr: 1 }}>Course Registration Fee (€) :</Typography>
                <TextField
                    type="number"
                    variant="outlined"
                    size="small"            
                    defaultValue={160}
                    inputProps={{ style: { textAlign: 'center' },  readOnly: true }} // Yazıyı ortalamak için
                    sx={{ width: '70px' }}
                />
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ mt: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ mr: 1 }}>Months:</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    defaultValue={1}
                    value={monthsBefore}
                    onKeyDown={(event) => event.preventDefault()} // Klavye girişini engelle
                    inputProps={{ style: { textAlign: 'center' }, min: 1, max: 24 }} // Metni ortalamak için stil              
                    onChange={handleMonthsChangeBefore}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <ArrowUpward sx={{ color: 'green', cursor: 'pointer' }} onClick={() => handleMonthsChangeBefore(1)} />
                            <ArrowDownward sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleMonthsChangeBefore(- 1)} />
                        </InputAdornment>
                        ),
                    }}
                    sx={{ width: '105px' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ mr: 1 }}>Amount (€) :</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={amountBefore}
                    defaultValue={100}
                    onKeyDown={(event) => event.preventDefault()}
                    inputProps={{ style: { textAlign: 'center' }, min: 100, max: 1000 }}
                    onChange={handleAmountChangeBefore}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <ArrowUpward sx={{ color: 'green', cursor: 'pointer' }} onClick={() => handleAmountChangeBefore(100)} />
                            <ArrowDownward sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleAmountChangeBefore(- 100)} />
                        </InputAdornment>
                        ),
                    }}
                    sx={{ width: '125px' }}
                    />
                </Box>
                <Typography variant="subtitle1" sx={{ color: 'orange', fontWeight: 'bold' }}>Total (€) :</Typography>
                <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={monthsBefore * amountBefore}
                    InputProps={{ readOnly: true }}
                    sx={{ mb: 2, width: '100px' }}
                />
                </Box>
                <Divider sx={{ my: 1 }} />
                <Typography variant="subtitle1" sx={{ backgroundColor: '#7C00FE', color: 'white', padding: '2px', borderRadius: '4px' }}>After Starting The Job</Typography>
                <Box sx={{ mt: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ mr: 1 }}>Months:</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={monthsAfter}
                    onKeyDown={(event) => event.preventDefault()}
                    inputProps={{ style: { textAlign: 'center' }, min: 1, max: 24 }}
                    onChange={handleMonthsChangeAfter}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <ArrowUpward sx={{ color: 'green', cursor: 'pointer' }} onClick={() => handleMonthsChangeAfter(1)} />
                            <ArrowDownward sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleMonthsChangeAfter(- 1)} />
                        </InputAdornment>
                        ),
                    }}
                    sx={{ width: '105px' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ mr: 1 }}>Amount (€) :</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={amountAfter}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={handleAmountChangeAfter}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <ArrowUpward sx={{ color: 'green', cursor: 'pointer' }} onClick={() => handleAmountChangeAfter(100)} />
                            <ArrowDownward sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleAmountChangeAfter(- 100)} />
                        </InputAdornment>
                        ),
                    }}
                    sx={{ width: '125px' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ color: 'orange', fontWeight: 'bold', mr: 1, mb: 1}}>Total (€) :</Typography>
                    <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={monthsAfter * amountAfter}
                        InputProps={{ readOnly: true }}
                        sx={{ mb: 2, width: '100px' }}
                    />
                </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                    <Typography variant="subtitle1" sx={{ color: 'blue' }}>One Time Payment</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: 'orange', fontWeight: 'bold', mr:1, mb: 1 }}>Total (€) :</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    defaultValue={160}
                    size="small"
                    value={amountOneTime}
                    onChange={handleChangeOneTimeAmount}
                    onBlur={handleBlurOneTimeAmount}
                    sx={{ mb: 2, width: '100px' }}
                    />
                </Box>
            </CardContent>
            </Card>
        ):( options.paymentType === 'CASH' & selectOptions.options === 'CustomOptions'  ? (
            <Card sx={{ width: '100%', maxWidth: '600px', margin: '0 auto', padding: '16px' }}>
                <CardContent>                    
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6" sx={{ mr: 1, flex: 1 }}>Course Registration Fee (€) :</Typography>
                        <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            defaultValue={0}
                            value={0} //courseregistirationfee
                            inputProps={{ style: { textAlign: 'center' }, readOnly: true }}
                            sx={{ width: '70px' }}
                        />
                    </Box>    
                    <Divider sx={{ my: 1 }} />                
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ mr: 1, color: 'red', fontWeight: 'bold', flex: 1 }}>Total (€) :</Typography>
                        <TextField
                            type="number"
                            variant="outlined"
                            //size="small"
                            value={cashAmount}
                            sx={{ width: '110px', fontWeight: 'bold' }}
                            onChange={handleChangeCashAmount}
                            onBlur={handleBlurCashAmount}
                            inputProps={{ style: { textAlign: 'center' } }}
                        />
                    </Box>
                </CardContent>
            </Card>
        ):null) }
    </>
  );
};

export default memo(CustomPaymentOptions);