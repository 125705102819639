import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/user/UserPage';
import UserRolesPage from './pages/roles/RolesPage';
import UserSettings from './pages/user/UserSettings'; //User Settings
import UserMessages from './pages/user/UserMessages'; //User Messages
import StudentPage from './pages/student/StudentPage';
import RecordedLecture from './pages/lectures/RecordedLectures';
import JobCenterWorkflow from './pages/student/JobCenterWorkflow';
import ExamPreparation from './components/course-coortination/examPreparation';
import LecturesPlanning from './pages/lectures/LecturesPlanning';
import AcademicCalendar from './pages/academicCalendar/AcademicCalendar';
import SyllabusSettings from './pages/academicSettings/SyllabusSettings';
import InterviewTransaction from './pages/student/InterviewTransactionsPage';
import StudentAttendances from './pages/student/StudentAttendancesPage';
import PaymentsPage from './pages/payments/PaymentsPage';
import MeetingPaticipants from './pages/zoom/MeetingPaticipantsPage';
import GoogleFiles from './pages/google/googleFiles';
import ZoomDownloaderPage from './pages/zoom/ZoomDownloader';
import ZoomUsersPage from './pages/zoom/ZoomUsersPage';
import ZoomRegistransPage from './pages/zoom/ZoomRegistrantsPage';
import ZoomMeetingsPage from './pages/zoom/ZoomMeetingsPage';
import TryHackMePage from './pages/tryhackme/TryHackMePage';
import LmsTranieePage from './pages/lms/LmsTranieePage';
import LmsHybridPage from './pages/lms/LmsHybridPage';
import LmsCoursesPage from './pages/lms/LmsCoursesPage';
import LabPage from './pages/labs/LabPage';
import AssetsPage from './pages/assets/AssetsPage';
import MailPage from './pages/eMail/EMail';
import ApprovalMailPage from './pages/approvalMail/ApprovalMail';
import Contracts from './components/finance/contracts';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import UserRoleDetails from './pages/user/userRoleDetails';
import ApprovalDetails from './pages/approvalMail/approvalMailDetail';
import getSession from './utils/getSession'; //Session Management
import { useAlert } from './utils/contexts/AlertContext'; 
import { Snackbar, Alert, } from '@mui/material';
import ProtectedPage from './utils/contexts/ProtectedRoute';
import CourseCoordinationSettings from './pages/student/CourseCoordinationSettings';
import MyFollowingInteractions from './pages/student/MyFollowingInteractions';

// ----------------------------------------------------------------------

export default function Router() {
  const session = getSession();
  
  const dynamicStructure = session && session.AllRoles ? session.AllRoles : [];  //Tanımlı tüm roller
  const accessMap = dynamicStructure.reduce((acc, { roletitle, PageNames }) => {
    if (PageNames) { // if PageNames <> null
      acc[roletitle] = PageNames.filter(pageName => pageName); // Filter empty strings
    } else {
      acc[roletitle] = []; //If PageNames is null, assign an empty array.
    }
    return acc;
  }, {});
 
  function canAccessPage(_session, requestedPage) {  
    if (_session?.user && Array.isArray(_session.user) && _session.user[0]?.roles) {
      const userRoles = _session.user[0].roles || {}; 
      const roles = Array.isArray(userRoles.Roles) ? userRoles.Roles : [];
      // Eğer roles dizisi boşsa, hataya düşmemek için kontrol et
      if (roles.length === 0) {
        return false;
      }      
      if (roles.some(role => role === 'Admin' || role === 'SystemAdmin')) {        
        return true;
      } else {
        
         return roles.some(role => {
          const allowedPages = accessMap[role];
          if(allowedPages){
            return allowedPages ? allowedPages.includes(requestedPage) : false; 
          }
        });
      }                  
    }
    //console.log('Session or roles not defined properly');
    return false;
  };
  
  // function canAccessPage(_session, requestedPage) {  
  //  // console.log("requestedPage: ", requestedPage);
  //  // if (_session && _session?.user[0]?.roles || '') {
  //   if (_session?.user && Array.isArray(_session.user) && _session.user[0]?.roles) {
  //   //if (_session?.user[0]?.roles){
  //     //console.log("_session.user[0].roles: ", _session.user[0].roles);
  //     const userRoles = _session.user[0].roles || {}; 
  //     //console.log("userRoles.Roles: ", userRoles.Roles);
  //     // Yeni yöntem: `some` kullanarak, `userRoles` içindeki her bir role bakılır ve `Admin` veya `SystemAdmin` rolleri aranır.
  //     //const roles = userRoles.Roles || [];
  //     const roles = Array.isArray(userRoles.Roles) ? userRoles.Roles : [];
  //     if (userRoles.Roles.some(role => role === 'Admin' || role === 'SystemAdmin')) {
  //      //console.log("burası admin girişi");
  //       return true;
  //     } else {            
  //       return userRoles.Roles.some(role => {
  //         // `accessMap` kullanarak, her bir rol için izin verilen sayfaları alırız.
  //         //console.log("role Description: ", role);
  //         //console.log("role: ", role === userRoles.Roles? `Evet sorun olmamalı` : `Maalesef eşit değil gibi o nedenle sorun var..`);
  //         const allowedPages = accessMap[role]; // Rol isimlerine göre izin verilen sayfaları alırız.
  //         return allowedPages && allowedPages.includes(requestedPage); // İstenen sayfanın izin verilenler arasında olup olmadığını kontrol ederiz.
  //       });
  //     }                  
  //   }
  //   //console.log("FALSE");
  //   return false;
  // };

  const routes = useRoutes([
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: session ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'UserPage') : false} redirectTo="/dashboard/app"><UserPage /></ProtectedPage> },
        { path: 'userroles', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'UserRolesPage') : false} redirectTo="/dashboard/app"><UserRolesPage /></ProtectedPage> },
        { path: 'student', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'StudentPage') : false} redirectTo="/dashboard/app"><StudentPage /></ProtectedPage> },
        { path: 'google', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'GoogleFiles') : false} redirectTo="/dashboard/app"><GoogleFiles /></ProtectedPage> },
        { path: 'tryhackme', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'TryHackMePage') : false} redirectTo="/dashboard/app"><TryHackMePage /></ProtectedPage> },
        { path: 'zoomdownloader', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'ZoomDownloaderPage') : false} redirectTo="/dashboard/app"><ZoomDownloaderPage /></ProtectedPage> },
        { path: 'zoomusers', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'ZoomUsersPage') : false} redirectTo="/dashboard/app"><ZoomUsersPage /></ProtectedPage> },
        { path: 'zoommeetings', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'ZoomMeetingsPage') : false} redirectTo="/dashboard/app"><ZoomMeetingsPage /></ProtectedPage> },
        { path: 'recordedlectures', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'RecordedLecture') : false} redirectTo="/dashboard/app"><RecordedLecture /></ProtectedPage> },
        { path: 'jobcenterworkflow', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'JobCenterWorkflow') : false} redirectTo="/dashboard/app"><JobCenterWorkflow /></ProtectedPage> },
        { path: 'exampreparation', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'ExamPreparation') : false} redirectTo="/dashboard/app"><ExamPreparation /></ProtectedPage> },
        { path: 'lecturesplanning', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'LecturesPlanning') : false} redirectTo="/dashboard/app"><LecturesPlanning /></ProtectedPage> },
        { path: 'academiccalendar', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'AcademicCalendar') : false} redirectTo="/dashboard/app"><AcademicCalendar /></ProtectedPage> },
        { path: 'lmstraniee', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'LmsPage') : false} redirectTo="/dashboard/app"><LmsTranieePage /></ProtectedPage> },
        { path: 'lab', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'LabPage') : false} redirectTo="/dashboard/app"><LabPage /></ProtectedPage> },
        { path: 'assets', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'AssetsPage') : false} redirectTo="/dashboard/app"><AssetsPage /></ProtectedPage> },
        { path: 'contracts', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'Contracts') : false} redirectTo="/dashboard/app"><Contracts /></ProtectedPage> },
        { path: 'mail', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'MailPage') : false} redirectTo="/dashboard/app"><MailPage /></ProtectedPage> },
        { path: 'aproval', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'ApprovalMailPage') : false} redirectTo="/dashboard/app"><ApprovalMailPage /></ProtectedPage> },
        { path: 'payments', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'PaymentsPage') : false} redirectTo="/dashboard/app"><PaymentsPage /></ProtectedPage> },
        { path: 'blog', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'BlogPage') : false} redirectTo="/dashboard/app"><BlogPage /></ProtectedPage> },
        { path: 'usersettings', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'UserSettings') : false} redirectTo="/dashboard/app"><UserSettings /></ProtectedPage> },
        { path: 'usermessages', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'UserMessages') : false} redirectTo="/dashboard/app"><UserMessages /></ProtectedPage> },
        { path: 'zoomregistrants/:meetingID', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'ZoomRegistransPage') : false} redirectTo="/dashboard/app"><ZoomRegistransPage /></ProtectedPage> },
        { path: 'meetingparticipants', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'MeetingPaticipants') : false} redirectTo="/dashboard/app"><MeetingPaticipants /></ProtectedPage> },
        { path: 'studentattendances', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'StudentAttendances') : false} redirectTo="/dashboard/app"><StudentAttendances /></ProtectedPage> },
        { path: 'userroledetails', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'UserRoleDetails') : false} redirectTo="/dashboard/app"><UserRoleDetails /></ProtectedPage> },
        { path: 'approvaldetails', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'ApprovalDetails') : false} redirectTo="/dashboard/app"><ApprovalDetails /></ProtectedPage> },
        { path: 'syllabussettings', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'SyllabusSettings') : false} redirectTo="/dashboard/app"><SyllabusSettings /></ProtectedPage> },
        { path: 'interviewtransaction', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'InterviewTransaction') : false} redirectTo="/dashboard/app"><InterviewTransaction /></ProtectedPage> },
        { path: 'lmscourses', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'LmsCoursesPage') : false} redirectTo="/dashboard/app"><LmsCoursesPage /></ProtectedPage> },
        { path: 'lms-hybrid', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'LmsHybridPage') : false} redirectTo="/dashboard/app"><LmsHybridPage /></ProtectedPage> },
        { path: 'coursecoordinationsettings', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'CourseCoordinationSettings') : false} redirectTo="/dashboard/app"><CourseCoordinationSettings /></ProtectedPage> },
        { path: 'myfollowinginteractions', element: <ProtectedPage canAccess={session ? canAccessPage(session, 'MyFollowingInteractions') : false} redirectTo="/dashboard/app"><MyFollowingInteractions /></ProtectedPage> },
        // { path: 'user', element: <ProtectedPage canAccess={canAccessPage(session, 'UserPage')} redirectTo="/dashboard/app"><UserPage /></ProtectedPage> },       
        // { path: 'userroles', element: <ProtectedPage canAccess={canAccessPage(session, 'UserRolesPage')} redirectTo="/dashboard/app"><UserRolesPage /></ProtectedPage> },
        // { path: 'student', element: <ProtectedPage canAccess={canAccessPage(session, 'StudentPage')} redirectTo="/dashboard/app"><StudentPage /></ProtectedPage> },
        // { path: 'google', element: <ProtectedPage canAccess={canAccessPage(session, 'GoogleFiles')} redirectTo="/dashboard/app"><GoogleFiles /></ProtectedPage> },
        // { path: 'tryhackme', element: <ProtectedPage canAccess={canAccessPage(session, 'TryHackMePage')} redirectTo="/dashboard/app"><TryHackMePage /></ProtectedPage> },
        // { path: 'zoomdownloader', element: <ProtectedPage canAccess={canAccessPage(session, 'ZoomDownloaderPage')} redirectTo="/dashboard/app"><ZoomDownloaderPage /></ProtectedPage> },
        // { path: 'zoomusers', element: <ProtectedPage canAccess={canAccessPage(session, 'ZoomUsersPage')} redirectTo="/dashboard/app"><ZoomUsersPage /></ProtectedPage> },
        // { path: 'zoommeetings', element: <ProtectedPage canAccess={canAccessPage(session, 'ZoomMeetingsPage')} redirectTo="/dashboard/app"><ZoomMeetingsPage /></ProtectedPage> },
        // { path: 'recordedlectures', element: <ProtectedPage canAccess={canAccessPage(session, 'RecordedLecture')} redirectTo="/dashboard/app"><RecordedLecture /></ProtectedPage> },
        // { path: 'lecturesplanning', element: <ProtectedPage canAccess={canAccessPage(session, 'LecturesPlanning')} redirectTo="/dashboard/app"><LecturesPlanning /></ProtectedPage> },
        // { path: 'academiccalendar', element: <ProtectedPage canAccess={canAccessPage(session, 'AcademicCalendar')} redirectTo="/dashboard/app"><AcademicCalendar /></ProtectedPage> },
        // { path: 'lmstraniee', element: <ProtectedPage canAccess={canAccessPage(session, 'LmsPage')} redirectTo="/dashboard/app"><LmsTranieePage /></ProtectedPage> },
        // { path: 'lab', element: <ProtectedPage canAccess={canAccessPage(session, 'LabPage')} redirectTo="/dashboard/app"><LabPage /></ProtectedPage> },
        // { path: 'assets', element: <ProtectedPage canAccess={canAccessPage(session, 'AssetsPage')} redirectTo="/dashboard/app"><AssetsPage /></ProtectedPage> },
        // { path: 'contracts', element: <ProtectedPage canAccess={canAccessPage(session, 'Contracts')} redirectTo="/dashboard/app"><Contracts /></ProtectedPage> },
        // { path: 'mail', element: <ProtectedPage canAccess={canAccessPage(session, 'MailPage')} redirectTo="/dashboard/app"><MailPage /></ProtectedPage> },
        // { path: 'aproval', element: <ProtectedPage canAccess={canAccessPage(session, 'ApprovalMailPage')} redirectTo="/dashboard/app"><ApprovalMailPage /></ProtectedPage> },
        // { path: 'payments', element: <ProtectedPage canAccess={canAccessPage(session, 'PaymentsPage')} redirectTo="/dashboard/app"><PaymentsPage /></ProtectedPage> },
        // { path: 'blog', element: <ProtectedPage canAccess={canAccessPage(session, 'BlogPage')} redirectTo="/dashboard/app"><BlogPage /></ProtectedPage> },
        // { path: 'usersettings', element: <ProtectedPage canAccess={canAccessPage(session, 'UserSettings')} redirectTo="/dashboard/app"><UserSettings /></ProtectedPage> },
        // { path: 'usermessages', element: <ProtectedPage canAccess={canAccessPage(session, 'UserMessages')} redirectTo="/dashboard/app"><UserMessages /></ProtectedPage> },
        // { path: 'zoomregistrants/:meetingID', element: <ProtectedPage canAccess={canAccessPage(session, 'ZoomRegistransPage')} redirectTo="/dashboard/app"><ZoomRegistransPage /></ProtectedPage> },
        // { path: 'meetingparticipants', element: <ProtectedPage canAccess={canAccessPage(session, 'MeetingPaticipants')} redirectTo="/dashboard/app"><MeetingPaticipants /></ProtectedPage> },
        // { path: 'studentattendances', element: <ProtectedPage canAccess={canAccessPage(session, 'StudentAttendances')} redirectTo="/dashboard/app"><StudentAttendances /></ProtectedPage> },
        // { path: 'userroledetails', element: <ProtectedPage canAccess={canAccessPage(session, 'UserRoleDetails')} redirectTo="/dashboard/app"><UserRoleDetails /></ProtectedPage> },           
        // { path: 'approvaldetails', element: <ProtectedPage canAccess={canAccessPage(session, 'ApprovalDetails')} redirectTo="/dashboard/app"><ApprovalDetails /></ProtectedPage> },           
        // { path: 'syllabussettings', element: <ProtectedPage canAccess={canAccessPage(session, 'SyllabusSettings')} redirectTo="/dashboard/app"><SyllabusSettings /></ProtectedPage> },           
        // { path: 'interviewtransaction', element: <ProtectedPage canAccess={canAccessPage(session, 'InterviewTransaction')} redirectTo="/dashboard/app"><InterviewTransaction /></ProtectedPage> },           
        // { path: 'lmscourses', element: <ProtectedPage canAccess={canAccessPage(session, 'LmsCoursesPage')} redirectTo="/dashboard/app"><LmsCoursesPage /></ProtectedPage> },           
        // { path: 'lms-hybrid', element: <ProtectedPage canAccess={canAccessPage(session, 'LmsHybridPage')} redirectTo="/dashboard/app"><LmsHybridPage /></ProtectedPage> },           
        // { path: 'coursecoordinationsettings', element: <ProtectedPage canAccess={canAccessPage(session, 'coursecoordinationsettings')} redirectTo="/dashboard/app"><CourseCoordinationSettings /></ProtectedPage> },           
      ],
    },  
    {
     // path: '/signup',
     // element: <SignUpPage />,      
    },
    {
      element: <SimpleLayout />, // Starting/opening location
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
         { element: <Navigate to="/login" />, index: true },
        // { path: '404', element: <Page404 /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',      
      element: <Navigate to="/dashboard/app" />, index: true
      //element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
};