import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import CreateStudentDialog from '../../components/lms/create-student-dialog';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Tooltip, 
  Snackbar,
  Alert
} from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { LmsListHead, LmsListToolbar } from '../../sections/@dashboard/lms';
// mock
import LMSUSERLIST from '../../_data/lms/getLmsUsers';
import { getStudents } from '../../_data/student/getStudent';
import config from '../../config';
const { PUBLIC_URL } = config;
const SaveIcon = `${PUBLIC_URL}/assets/icons/save_as.svg`;

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Name Surname', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'match', label: 'Register Type', alignRight: false },
  { id: '' },
];

export default function LmsPage() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterTraineerName, setTraineerName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [lmsUserDatas, setLmsUserDatas] = useState([]);  
  const [recordsNumber, setrecordsNumber] = useState(0);
  const updateDialogRef = useRef();
  const [selectedStudentData, setSelectedStudentData] = useState(null);
  const [studentDatas, setStudentDatas] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [filteredData, setFilteredData] = useState([]);  // Filtrelenmiş veriler için yeni state

  const getstudentDatas = async () => {
    try {
      const data = await getStudents();
      setStudentDatas(data);
    } catch (error) {
      console.error(error);
      setStudentDatas([]);
    }
  }; 

  const getlmsUserDatas = async (pageNum) => {
    try {
      const data = await LMSUSERLIST(pageNum);
      setrecordsNumber(data.meta.total);
      setLmsUserDatas(data.lmsUsersInfo);
      setFilteredData(data.lmsUsersInfo);
    } catch (error) {
      console.error(error);
      setLmsUserDatas([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {    
    getlmsUserDatas(1);    
  }, []);

  useEffect(() => {
    getstudentDatas();
  }, []);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lmsUserDatas.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    const page = newPage + 1;
    setPage(newPage);
    getlmsUserDatas(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    const value = event.target.value.toLowerCase(); //Arama alanina buyuk kucuk harf karisik yazabilsinler
    setPage(0);
    const filtered = lmsUserDatas.filter((user) =>
      user.name.toLowerCase().includes(value) || user.email.toLowerCase().includes(value)
    );
    setTraineerName(event.target.value);
  };

  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleEditStudent = (row, isEmail) => {
    try {
    //  console.log('row: ', row);
      if (isEmail) {
        showMessage(t('RegistrationOnStudent'), 'error');
      } else {
        setSelectedStudentData(row);
        if (updateDialogRef.current) {
          updateDialogRef.current.handleClickOpen();
        }
      }
    } catch (error) {
      console.log("error: ", error);;      
    }
  };
  
  const refreshStudentData = async () => {
    try {
      const data = await LMSUSERLIST();
      setrecordsNumber(data.length);
      setLmsUserDatas(data);
      setFilteredData(data);
    } catch (error) {
      console.error(error);
    }
  };

  //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - lmsUserDatas.length) : 0;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length);

  const studentEmails = new Set(studentDatas.map(student => student.email));

  return (
    <>    
      <Helmet>
        <title> LMS Users | Oak Academy </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Oak Lms Users List
          </Typography>
        </Stack>

        <Card>
          {/* <LmsListToolbar numSelected={selected.length} filterName={filterTraineerName} onFilterName={handleFilterByName} /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <LmsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                
                {Array.isArray(filteredData) && filteredData.length > 0 ? (                  
                  filteredData.map((row) => {
                    const { id, name, email, avatarUrl } = row;  
                    const selectedTraniee = selected.indexOf(id) !== -1;
                    const isEmailInStudentDatas = studentEmails.has(email);
                    return (
                      <TableRow 
                        hover key={id !== undefined ? id.toString() : `row-${name}`}
                        tabIndex={-1} 
                        selected={selectedTraniee}
                        style={{ backgroundColor: isEmailInStudentDatas ? 'lightblue' : 'white' }}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox checked={selectedTraniee} onChange={(event) => handleClick(event, id)} /> */}
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={id !== undefined ? id.toString() : ''} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          {'LMS-HYBRID'}
                          <Tooltip title={t('Save To Trainee List')}>
                            <IconButton onClick={() => handleEditStudent(row, isEmailInStudentDatas)}>
                              <img src={SaveIcon} alt="Save_Trainees" style={{ width: 24, height: 24 }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}


                  
                  {/* {filteredData.map((row) => {
                    const { id, name, email, avatarUrl } = row;  
                    const selectedTraniee = selected.indexOf(id) !== -1;
                    const isEmailInStudentDatas = studentEmails.has(email);
                    return (
                      <TableRow 
                        hover key={id !== undefined ? id.toString() : `row-${name}`} 
                        tabIndex={-1} 
                        //role="checkbox" 
                        selected={selectedTraniee}
                        style={{ backgroundColor: isEmailInStudentDatas ? 'lightblue' : 'white' }}
                      >
                        <TableCell padding="checkbox">
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={id !== undefined ? id.toString() : ''} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          {'LMS-HYBRID'}
                          <Tooltip title={t('Save To Trainee List')}>
                            <IconButton onClick={() => handleEditStudent(row, isEmailInStudentDatas)}>
                              <img src={SaveIcon} alt="Save_Trainees" style={{ width: 24, height: 24 }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}




                </TableBody>
                {/* {!lmsUserDatas.length && ( */}
                {!filteredData.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: 'center' }}>
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterTraineerName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[50]}
            component="div"
            count={recordsNumber}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} sx={{ width: 450, minWidth: 288, height: 75, fontSize: 14, fontStyle: 'normal'}} >
          {alertMessage}
        </Alert>
      </Snackbar>
      <CreateStudentDialog ref={updateDialogRef} initialData={selectedStudentData} onStudentAdded={refreshStudentData}/>      
    </>
  );
};
