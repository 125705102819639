import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const emailusers= async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/email/users`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const info = response.data || [];
        console.log('emailusers: ', info);
        const usersList = info.map((item, index) => {
            return {
                email: item.email || '',
                fullname: item.fullname || '',
                department: item.department || '',
                domain_name: item.domain_name || '',
                status_id: item.status_id || '',
                password: item.password || '',
            };
        });
        return usersList;
    } catch (error) {
        return [];
    }
};

export default emailusers;
