import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getLmsHybridTrainee = async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/students/getlmshybridtrainees`, {
            params: [6, 7], // Statusler burada parametre olarak gönderiliyor
            headers: {
                'Authorization': `Bearer ${token}` // Token değerini headers'a ekledik
            }
        });
        const info = response.data || [];
        return info;  // response.data iki kez kullanılıyordu, info olarak döndürelim
    } catch (error) {
        return [];
    }
};

export { getLmsHybridTrainee };
