
import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const setStatusEmailUser= async (mails, status) => {
  try {
    const token = await getToken();
    const response = await axios.patch(`${BaseUrl}/email/users/setstatus`, {
        emails: mails,
        status: status
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
      console.error("Update error: ", error);
      return [];
  }
};
export default setStatusEmailUser;
