import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ConfirmDialog from './confirmDialog';
import { format, parseISO, isAfter } from 'date-fns';

// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow, MenuItem, TableBody,TableCell,
  Container,Typography, IconButton, TableContainer, TablePagination, Snackbar, Alert, Dialog, 
  DialogActions, DialogContent, DialogTitle, TextField, Select, InputLabel, FormControl, FormHelperText,
  CircularProgress, Box, Link, Breadcrumbs, FormControlLabel} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ApprovalListHead, ApprovalListToolbar } from '../../sections/@dashboard/approvals';
// mysql datase
import ApprovalUsersMailDetails from '../../_data/approvals/getApprovalUserMailDetails';
import CreateApprovalDialog from '../../components/approvals/create-approval-dialog';
import RequestComfirmDialog from '../../components/approvals/request-confirm-dialog';
import SendReqConfirm from '../../_data/approvals/sendReqConfirm';
import getSession from '../../utils/getSession';
import formatDateTime from '../../utils/dateTimeFormatter';
import { Bolt } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import PreferencesDialog from './preferencesDialog';
import getApprovalDetailStatus from '../../_data/approvals/getApprovalDetailStatus'

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'mailtype', label: 'Mail Type', alignRight: false },
  { id: 'permission_content', label: 'Permission Content', alignRight: false },
  { id: 'permission_date', label: 'Permission Date', alignRight: false },  
  { id: 'approved_date', label: 'Approved Date', alignRight: false },  
  { id: 'numberofcalls', label: 'Number Of Calls', alignRight: false },  
  { id: 'Approved', label: 'Approved', alignRight: false },  
  { id: 'permission_description', label: 'Description', alignRight: false },  
  { id: '' },
];

const currentDate = formatDateTime();
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ApprovalDetailsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  //const pathnames = location.pathname.split('/').filter((x) => x);
  const pathnames = location.pathname.split('/').filter((x) => x && x !== 'dashboard'); // 'dashboard' segmentini filtrele
  const pk = location.state?.pk;
  const email = location.state?.email;
  const fullname = location.state?.fullname;
  //console.log('ApprovalDetailsPage-pk: ', pk);

  const account = getSession();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('email');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success')
  const [alertMessage, setAlertMessage] = useState(''); 
  const [createdby, setCreatedBy] = useState(1);  
  const [approvalMailDetailList, setApprovalMailDetailList] = useState([]);  

  const handleClose = () => {
    setMsgOpen(false);
  };
  const handleDownRecord = async (event) => {
    try {          
      const label = event.currentTarget.textContent;
      if(selected.length > 0){
        switch(label){          
          case 'Repeat Request':
            //TODO: Mail gonderilecektir eski ve kullanilmayan mailleri istekleri gecersiz olacaktir gibi bir confirm message goster.
            //TODO: Istek sayisini tuttugun trigger ile ilgili olabilir +1 den fazla artis yapiyor. 'NumberOfCalls'
          //  console.log('108. handleDownRecord: ', selected[0].content, email, fullname, selected[0].mailtype, selected[0].desc);
            handleConfirmDialogOpen(selected[0].content, email, fullname, selected[0].mailtype, selected[0].desc);            
            break;
            case 'Approval Details':                   
              //console.log('Approval Details: ', selected[0].pk);
              const PreferencesData = await getApprovalDetailStatus(selected[0].pk);
              //console.log('PreferencesData:', PreferencesData);
              setPreferencesData(PreferencesData);
              setIsDetailRecordsDialogOpen(true);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };  
  const [preferencesData, setPreferencesData] = useState([]);


  const handleRepeatRequest = async (currentAction) => {    
    try {
      setCreatedBy(account.user[0].iduser);      
      const data = await SendReqConfirm(createdby, currentAction.email, currentAction.content, currentAction.permission_description, currentAction.mailtype, currentAction.fullname);
    //  console.log('data-137: ', data);
      let errorMessage = "";
      let successMessage = "Your registration has been successfully created and a confirmation email has been sent."; //TODO: Hata yönetim yapısından ve dynamic olarak alınması daha iyi olur. 
      if(data.status === 500){
        if (data.error.startsWith("Duplicate entry")){
          errorMessage = "Do not repeat the recording for which permission was previously requested.";
        }
        setLoading(false); //stop circularProgress
        setSeverity('error');
        setAlertMessage(errorMessage);
        setMsgOpen(true);
      }
      else if(data.status === 200){
        setMsgOpen(false);
        fetchUsers();
        setSeverity('success');
        setAlertMessage(successMessage);
        setMsgOpen(true);
        setLoading(false); //stop circularProgress
        handleCloseRequestDialog();
      }else{
        setMsgOpen(false);
        fetchUsers();
        setSeverity('error');
        setAlertMessage('Do not send e-mail!');
        setMsgOpen(true);
        setLoading(false); //stop circularProgress
        handleCloseRequestDialog();
      }
    } catch (error) {
      console.log("Error(handleSubmitNewPaticipantApprovalDetail) : ", error);
    }
  };

  const dataRows = [];
  const [selectedMail, setSelectedMail] = useState("");
  const [isDetailRecordsDialogOpen, setIsDetailRecordsDialogOpen] = useState(false);
  const handleDetailRecordsDialogOpen = (email) => {
    try {
      setSelectedMail(email);
      setIsDetailRecordsDialogOpen(true);
    } catch (error) {      
    }
  }

 const handleCloseDetailRecordsDialog = () => {
    setIsDetailRecordsDialogOpen(false);
  };  
  
  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const handleOpenRequestDialog = (email, fullname) => { 
    try {
      setSelectedMail(email);
      setIsRequestDialogOpen(true);
    } catch (error) {
      console.log("handleOpenRequestDialog error: ", error);
    }
  };

  const handleCloseRequestDialog = () => {
    setIsRequestDialogOpen(false);
  };

  const MENU_OPTIONS = [
    { label1: 'Repeat Request ', },
    { label2: 'Approval Details', },
    { label3: 'Active', },
  ];

  const mailTypes = [
    { label: 'Language English', value: 'AENLAMFSMC' },
    { label: 'Language German', value: 'ADELAMFSMC' },
  ]
 

  const approvalTypes = [
    { label: 'Not Send Mail', value: 0 },
    { label: 'Pending', value: 1 },
    { label: 'Approved', value: 2 },
  ]
  const getApprovalLabel = (approved) => {
    const approvalType = approvalTypes.find(type => type.value === approved);
    return approvalType ? approvalType.label : '';
  };

  const [contentLinkError, setContentLinkError] = useState(false);
  const [contentDescriptionError, setContentDescriptionError] = useState(false);
  const [selectedLanguageError, setSelectedLanguageError] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedFullname, setSelectedFullname] = useState('');
  
  const handleChangeMailTypes = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const handleSubmitNewPaticipantApprovalDetail = async (event) => {    
    // Reset Error States
    setContentLinkError(false);
    setContentDescriptionError(false);
    setSelectedLanguageError(false);

    // Checked for each field
    if (!contentLink) {
      setContentLinkError(true);
      return;
    }
    if (!contentDescription) {
      setContentDescriptionError(true);
      return;
    }
    if (!selectedLanguage) {
      setSelectedLanguageError(true);
      return;
    }
    try {       
      setCreatedBy(account.user[0].iduser);
      setLoading(true); //start circularProgress
      setStatusMessage('Submitting your request...');
      setStatusMessage('Record is saved and sending e-mail...');
      const data = await SendReqConfirm(createdby, selectedMail, contentLink, contentDescription, selectedLanguage, selected[0].fullname);
      let errorMessage = "";
      let successMessage = "Your registration has been successfully created and a confirmation email has been sent."; //TODO: Hata yönetim yapısından ve dynamic olarak alınması daha iyi olur. 
      if(data.status === 500){
        if (data.error.startsWith("Duplicate entry")){
          errorMessage = "Do not repeat the recording for which permission was previously requested.";
        }
        setLoading(false); //stop circularProgress
        setSeverity('error');
        setAlertMessage(errorMessage);
        setMsgOpen(true);
      }
      else{
        fetchUsers();
        setSeverity('success');
        setAlertMessage(successMessage);
        setMsgOpen(true);
        setLoading(false); //stop circularProgress
        handleCloseRequestDialog();
      }
    } catch (error) {
      console.log("Error(handleSubmitNewPaticipantApprovalDetail) : ", error);
    }
  };  

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState({ content: '', email: null, fullname: '',  mailtype: null, permission_description: ''});
  const handleConfirmDialogOpen = (content, email, fullname, mailtype, permission_description) => {    
    setCurrentAction({content, email, fullname, mailtype, permission_description});    
    setOpenConfirmDialog(true);
  };
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirm = async () => {
    try {
      setSeverity('info');
      setAlertMessage(`Please wait... A confirmation email is sent again.`);
      setMsgOpen(true);
      await handleRepeatRequest(currentAction);
      handleConfirmDialogClose();
    } catch (error) {
      console.error("Error in handleConfirm:", error);
    }    
  };

  const fetchUsers = async () => {
    try {      
        const data = await ApprovalUsersMailDetails(pk);
        setApprovalMailDetailList(data);
    } catch (error) {
        console.error("Error fetching users: ", error);
    }
  };
  
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 200000); // Refresh per 200 second
    return () => clearInterval(intervalId); // Cleanup fonksiyonu
  }, []);
  
  const handleCreateApproval = async (success, props) => { //callback fonksiyonu ile chilp paneldeki veriyi main/parent page üzerine alıp düzenleme veya güncelle yapıyoruz.
    if (success) {
      props.onApprovalSuccess();
    }
  };
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = approvalMailDetailList.map((n) => ({ email: n.email, fullname: n.fullname }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }; 

  const handleClick = (event, select) => {
    let newSelected = [...selected];  
    const selectedIndex = newSelected.findIndex(item => item.pk === select.idparticipants_approvals_details);
    if (selectedIndex === -1) {
      newSelected.push({pk: select.idparticipants_approvals_details, content: select.permission_content, mailtype: select.mailtype, desc: select.permission_description});
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

// Circular Progress
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('Ready to submit');

  //Get Table Background Color Style 
  const getRowStyle = (approved) => {
    if (approved === 1) {
      return { backgroundColor: '#ffcccb' }; // Örneğin, pending_approvals > 0 için açık kırmızı
    } else {
      return { backgroundColor: '#90ee90' }; // Örneğin, pending_approvals = 0 için açık yeşil
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);    
  };

  const [contentDescription, setContentDescription] = useState("");
  const handleContentDescriptionChange = (event) => {
    setContentDescription(event.target.value);
  }

  const [contentLink, setContentLink] = useState("");
  const handleContentLinkChange = (event) => {
    setContentLink(event.target.value);
  }
  
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - approvalMailDetailList.length) : 0;
  const filteredUsers = applySortFilter(approvalMailDetailList, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;
  const [permissionContent, setPermissionContent] = useState("");
  const [link, setLink] = useState("");

  const getLatestPermissionDate = (users) => {
    return users.reduce((latest, user) => {
      const userDate = parseISO(user.permission_date);
      return isAfter(userDate, latest) ? userDate : latest;
    }, new Date(0));
  };
  const latestPermissionDate = getLatestPermissionDate(filteredUsers);


  return (
    <>
      <Helmet>
        <title> Content Permission Details | Oak Academy </title>
      </Helmet>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" onClick={() => navigate('/dashboard/aproval')}
        style={{ cursor: 'pointer' }}>
          Approval Mails
        </Link>
        {pathnames.map((value, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography color="textPrimary" key={routeTo}>
              Approval Mails Details
            </Typography>
          ) : (
            <Link color="inherit" onClick={() => navigate(routeTo)} key={routeTo}>
              Approval Mails Details
            </Link>
          );
        })}
      </Breadcrumbs>     
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Approval Details For
            <Box component="span" sx={{ color: 'secondary.main' }}> "{ fullname } / { email }" </Box>
          </Typography>
        </Stack>
        <Card>
          <ApprovalListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer padding="normal" sx={{ width: '100%', minWidth: 900 }}>
              <Table>
                <ApprovalListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={approvalMailDetailList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {                    
                    const { avatarUrl, idparticipants_approvals_details, idparticipants_approvals, permission_content, mailtype, 
                      permission_date, approved_date, numberofcalls, approved, permission_description, iduser  } = row;
                    //const selectedPermission = selected.some(item => item.idparticipants_approvals_details === idparticipants_approvals_details);  
                    const isItemSelected = selected.indexOf(idparticipants_approvals_details) !== -1;
                    const labelId = `enhanced-table-checkbox-${idparticipants_approvals_details}`;                  
                    return (
                      <TableRow 
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={idparticipants_approvals_details}
                        selected={isItemSelected}
                        sx={{ backgroundColor: getRowStyle(approved) }}                         
                        >                       
                        <TableCell padding="checkbox">              
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, {idparticipants_approvals_details, mailtype, permission_content, permission_description})} />                          
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {mailtype === 'AENLAMFSMC' ? 'Language English' : mailtype === 'ADELAMFSMC' ? 'Language German' : '' }
                        </TableCell>
                        <TableCell align="left" sx={{ width: '30%' }}>
                          <Link href={permission_content} target="_blank" rel="noopener" style={{ cursor: 'pointer' }}>            
                            {permission_content}
                          </Link>
                        </TableCell>                        
                        <TableCell align="left" sx={{ width: '15%' }}>
                          {format(parseISO(permission_date), 'yyyy-MM-dd HH:mm:ss.SSS')}
                          {parseISO(permission_date).getTime() === latestPermissionDate.getTime() && (
                            <span style={{ fontSize:'22px', fontWeight: 'bold', color: 'black' }}> *</span>
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ width: '15%' }}>{approved_date}</TableCell>
                        <TableCell align="left" sx={{ width: '5%' }}>{numberofcalls}</TableCell>
                        <TableCell align="left" sx={{ color: 'secondary.main', fontWeight: 'bold', fontSize: '16px', width: '5%' }}>{getApprovalLabel(approved)}</TableCell>
                         <TableCell align="left" sx={{ width: '45%' }}>{permission_description}</TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25]}
            component="div"
            count={approvalMailDetailList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
            <Box 
              ml={1}
              height={18}
              mb={1}
              fontSize={12}              
              color= "red"
            >
            <span style={{ color: 'black', fontSize: '18px', fontWeight: 'bold'}}>* </span> : Latest user approval date.
            </Box> 
        </Card>        
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 240,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleDownRecord} 
          sx={{
              typography: 'body1',
              color: 'red',
              '&:hover': {
              bgcolor: 'mycolor.dark'}, width: 250} } >
          <Iconify icon={'eva:undo-fill'} sx={{ mr: 2, color: 'mycolor.main'}} />
          <Typography variant="body1" color="mycolor.main">
            Repeat Request
          </Typography>
        </MenuItem>              
         <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'secondary.main' }}>
          <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }} />
            Approval Details
        </MenuItem>
      </Popover>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={msgOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert severity={severity} onClose={handleClose} sx={{ 
          minWidth: '300px',
          maxWidth: '90%',
          width: 'auto',
          fontSize: 14, 
          fontWeight: 'normal', 
          fontStyle: 'normal',
          overflow: 'hidden', 
          wordWrap: 'break-word',
        }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirm}
        currentAction={currentAction}
      />

      <PreferencesDialog
        open={isDetailRecordsDialogOpen}
        onClose={handleCloseDetailRecordsDialog}
        fullname={fullname}
        preferences={preferencesData}
      />
    </>
  );
}


