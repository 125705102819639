import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getLmsCourseUserProgress = async (userid) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/lms/course/userprogress`, {
      params: {userid},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const courses = response.data.courses || [];
    const infoArray = Array.isArray(response.data) ? response.data : [response.data];
    const lmsCoursesInfo = infoArray.map((item, index) => {
      return {
        id: item.id || '',
        name: item.name || '',
        email: item.email || '',
        is_published: item.is_published || '',
        role: item.role || '',
        last_sign_in_ip: item.last_sign_in_ip || '',
        courses: item.courses || '',
      };
    });
    return {lmsCoursesInfo};
  } catch (error) {
    console.log('error: ', error )
    return [];
  }
};
export default getLmsCourseUserProgress;
