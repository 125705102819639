import axios from 'axios';
import config from '../../../config';
const { BaseUrl } = config;

const setStopVm = async (node, vmid) => {
    try {
        const response = await axios.post(`${BaseUrl}/lab1/stopvm`, {
          node: node, 
          vmid: vmid
        });  
       // console.log("setStopVm: ", response);
        return response.data;
    } catch (error) {
        console.log("Error[setStopVm]: ", error);
        return error;
    }
};

const setStartVm = async (node, vmid) => {
  try {
      const response = await axios.post(`${BaseUrl}/lab1/startvm`, {
        node: node, 
        vmid: vmid
      });  
     // console.log("setStartVm: ", response);
      return response.data;
  } catch (error) {
      console.log("Error[setStartVm]: ", error);
      return error;
  }
};

export {setStopVm, setStartVm};