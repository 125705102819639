import { useEffect, useState } from 'react';
import { useLoading } from '../utils/contexts/LoadingContext';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppDiskRange,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import ZOOMSTORAGEUSAGE from '../_data/zoom/zoomStorageUsage';
import { getNoS } from '../_data/student/getStudent';
import getSession from '../utils/getSession'; //Session Management
import React, { useContext } from 'react';
import { AccountContext } from '../utils/contexts/AccountContext';
import { getTodayIntersactions } from '../_data/dashboard_widgets/getTodayIntersactions';


// ----------------------------------------------------------------------

function canAccessWidget(userRoles, widgetRoles) {  
  if (userRoles && Array.isArray(userRoles)) {        
    if (Array.isArray(widgetRoles)) {
      return widgetRoles.some(role => userRoles.includes(role));
    } else {
      return userRoles.includes(widgetRoles);
    }
  }
  return false;
}

export default function DashboardAppPage(intervalDuration) {
  const { showLoading, hideLoading } = useLoading();
  const theme = useTheme();  
  const session = getSession();
  const roles = session?.user[0]?.roles?.Roles || [];
  //const { account} = useContext(AccountContext);  // Kullanıcı bilgilerini al
 // console.log("account.permission: ", account.user);
  // console.log("session: ", session.user[0].roles);
  // const [roles, setRoles] = useState(session.user[0].roles);
  
  
  
  //const userHasPermissionToViewConversionRates = account && account.permissions.includes("viewConversionRates"); // Kullanıcının belirli bir yetkiye sahip olup olmadığını kontrol et
  const userHasPermissionToViewConversionRates = true;

  //  const userHasPermissionToViewConversionRates = user.permissions.includes("viewConversionRates"); 

  // withPermission HOC'unu ve diğer komponent tanımlarınızı burada kullanın
  // ...
  const userHasPermission = true;

  // withPermission HOC'u
  function withPermission(Component, userHasPermission) {
    return props => userHasPermission ? <Component {...props} /> : null;
  }

  // Kullanımı
  const AppConversionRatesWithPermission = withPermission(AppConversionRates, userHasPermission);

    const [canUpdate, setCanUpdate] = useState(true);
    const [storage_used, setStorage_used] = useState(0);
    const [plan_used, setPlan_used] = useState(0);  
    const [nos, setNoS] = useState([]);
    const [todayContactNumber, setTodayContactNumber] = useState(0);
    const [tooltipMessage, setTooltipMessage] = useState([]);

  const getNos = async () => {   
    try {
      const NoS = await getNoS();
      setNoS(NoS);     
      if (NoS && NoS.length > 0) {
        setNoS(NoS);
      } else{
        setNoS(null);
      }   
    }catch(error){
      console.error(error);
      setNoS(null);
    }
  }

  const getTodayIntersactionsNumber = async () => {
    try {
      const todayContact = await getTodayIntersactions();
      const list = Array.isArray(todayContact) ? 
      todayContact.map((item, index) => ({ id: String(index + 1), label: `${item.std_namesurname} (${item.follower_namesurname})`})) : 
      [];
      setTooltipMessage(list)
      //const message = todayContact.map((info, index) => `Student: ${info.std_namesurname} - Follower: ${info.follower_namesurname}`).join('\n');
     //const tasks = todayContact.map((info) => `Student: ${info.std_namesurname} - Follower: ${info.follower_namesurname}`);
      //setTooltipMessage(tasks);      
      setTodayContactNumber(todayContact.length || 0);
    } catch (error) {
      console.log('getTodayContacts-Error: ', error);
    }
  }

  const getZoomStorageUsage = async () => {
    try {
        const cloudStorege = await ZOOMSTORAGEUSAGE();
        if (cloudStorege.data && cloudStorege.data.length > 0) {
          setStorage_used(extractNumberFromGBString(cloudStorege.data[0].storage_used));
          setPlan_used(extractNumberFromTBString(cloudStorege.data[0].plan_used));
      } else {
        setStorage_used(null);
        setPlan_used(null);
      }                                
    } catch (error) {
        console.error(error);
        setStorage_used(null);
        setPlan_used(null);
    }
  };

  const [completed, setCompleted] = useState(0);
  useEffect(() => {
    try {
      showLoading();
      getNos();
      getTodayIntersactionsNumber();
      getZoomStorageUsage();    
      const interval = setInterval(() => {
        getZoomStorageUsage();
        getTodayIntersactionsNumber();
      }, 600000); // Örneğin 5 dakika için: 10 * 60 * 1000       
       return () => clearInterval(interval); // Component unmount olduğunda interval temizlenir.      
    } catch (error) {
      console.log('DashboardApp-useEffect: ', error);
    }finally{
      hideLoading();
    }

  }, [intervalDuration]);

  const manualUpdate = () => {
    if (canUpdate) {
      getZoomStorageUsage();
      setCanUpdate(false);
      setTimeout(() => setCanUpdate(true), 300000); //en az 5 dakika geçtikten sonra manuel update butone enable olsun.
    }
  };

  const extractNumberFromGBString = (gbString)=> {
    return parseFloat(gbString.replace(" GB", ""));
  }
  const extractNumberFromTBString = (tbString)=> {
    const tbValue = parseFloat(tbString.replace(" TB", ""));
    return tbValue * 1000;  // TB'yi GB cinsinden döndürür.
  }
  const validnos = Array.isArray(nos) ? nos : [];
  //const validFilteredStudent = filteredStudents || []; //TODO: filteredStatus undefined ise boş bir dizi kullan
    //setrecordsNumber(validFilteredStudent.length);

    //const taskList = tooltipMessage.map(item => ({ id: String(item.id + 1), label: item.label }));

  return (
    <>
      <Helmet>
        <title> Dashboard | Oak Academy </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Oak Academy
        </Typography>
        {/* <Box>
          <AppCircularProgress maxValue={10} /> 
        </Box>       
        */}
        
        <Grid container spacing={3}>
          {canAccessWidget(roles, ['HR', 'Course Coordinator', 'Job Center Coordinator', 'Admin']) && (          
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title={"Today's Contact Notifications"} 
                total={todayContactNumber} 
                color="error"
                icon={'ant-design:notification-outlined'}
                tooltipMessage = {JSON.stringify(tooltipMessage)}
              />
            </Grid>
          )}          
          {canAccessWidget(roles, ['HR', 'Course Coordinator', 'Job Center Coordinator', 'Admin']) && (          
            <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Today's Contacts Tasks"
              subheader="Candidate to be Contacted."
              owner="Course Coordinators"
               list={tooltipMessage}
            />
          </Grid>            
          )}
          {validnos.length > 0 && canAccessWidget(roles, ['HR', 'Admin']) &&  (  // Todo: İlk değer boş geliyor bu nedenle hata vermemesi için kontrol yapıyorum. Hemen akabinde validnos değeri dolduğu için sonradan değer geliyor ve işleme alınıyor. 
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title={validnos[0].coursename} total={validnos[0].numbers} icon={'ant-design:usergroup-add-outlined'} />            
              </Grid>
          )}
          {validnos.length > 0 && canAccessWidget(roles, ['HR', 'Admin']) && (
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title={validnos[1].coursename} total={validnos[1].numbers} color="warning" icon={'ant-design:usergroup-add-outlined'} />
            </Grid>
          )}
          {validnos.length > 0 && canAccessWidget(roles, ['HR', 'Admin']) && (
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title={validnos[2].coursename} total={validnos[2].numbers} color="success" icon={'ant-design:usergroup-add-outlined'} />
              </Grid>
          )}                              
          { canAccessWidget(roles, ['Marketing', 'Admin']) && (
            <Grid item xs={12} md={6} lg={8}>
              <AppTasks
                title="Tasks"
                subheader="The deadline for these tasks is approaching."
                owner="Seyfi" //Todo: bunu sonradan ekledim. AppTask.js üzerinde de owner işlemi yaptım. ***Üzerinde çalışacağım***
                list={[
                  { id: '1', label: 'Create FireStone Logo For BATCH-9' },
                  { id: '2', label: 'Add SCSS and JS files if required' },
                  { id: '3', label: 'Stakeholder Meeting' },
                  { id: '4', label: 'Scoping & Estimations' },
                  { id: '5', label: 'Student Sprint Showcase' },
                ]}
              />
            </Grid>
          )}
          { canAccessWidget(roles, ['Admin', 'Zoom Asistant']) && (
            <Grid item xs={12} md={6} lg={4}>
              {/* <AppWidgetSummary title="Current Disk Capacity for OAK Zoom Recordings" total={714000} icon={'ant-design:android-filled'} /> */}
              {/* <AppWidgetSummary title="Zoom Disk Utilization Rate: " total={623.81/10} color="info" icon={'ant-design:cloud-server-outlined'} /> */}
              {/* // Todo: 623.81/10 ifadesi zoom üzerindeki kapasite kotamız 1TB ve gelen değer bunun % olarak göstermek için formülü sadeleştirince 10 sayısına bölmek anlamına geliyor. */}
              <AppDiskRange
                title="Zoom Disk Utilization Rate"
                chartData={[
                  { label: 'Used Disk Space', value: storage_used},
                  { label: 'Free Disk Space', value: (plan_used-storage_used)},
                ]}
                chartColors={[
                  theme.palette.error.main,
                  theme.palette.success.main,              
                ]}
              />
            </Grid>
          )}
          { canAccessWidget(roles, ['Marketing', 'Admin']) && (
            <Grid item xs={12} md={6} lg={8} >
              {/* <AppConversionRatesWithPermi */}
              <AppWebsiteVisits            
                title="Website Visits Into Oak Google Ads"
                subheader="(+43%) than last year"
                chartLabels={[
                  '01/01/2023',
                  '02/01/2023',
                  '03/01/2023',
                  '04/01/2023',
                  '05/01/2023',
                  '06/01/2023',
                  '07/01/2023',
                  '08/01/2023',
                  '09/01/2023',
                  '10/01/2023',
                  '11/01/2023',
                ]}
                chartData={[
                  {
                    name: 'hackeracademy',
                    type: 'column',
                    fill: 'solid',
                    data: [23, 11, 22, 27, 13, 42, 37, 21, 44, 22, 30],
                  },
                  {
                    name: 'oak de',
                    type: 'area',
                    fill: 'gradient',
                    data: [44, 55, 41, 67, 22, 63, 21, 41, 56, 27, 43],
                  },
                  {
                    name: 'oak uk',
                    type: 'line',
                    fill: 'solid',
                    data: [30, 25, 36, 30, 45, 55, 64, 52, 59, 36, 39],
                  },
                ]}
              />
            </Grid>
          )}
          { canAccessWidget(roles, 'Admin') && (
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 780 },
                { label: 'Turkey', value: 990 },
                { label: 'Netherlands', value: 320 },
                { label: 'United States', value: 1100 },
                { label: 'United Kingdom', value: 580 },
              ]}
            />
          </Grid>
          )} 
          { canAccessWidget(roles, 'Admin') && (
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['TryHackMe', 'Digital Campus', 'Zoom', 'Quizzes', 'Discord', 'Interns']}
              chartData={[
                { name: 'BATCH-3', data: [80, 50, 30, 40, 100, 20] },
                { name: 'BATCH-4', data: [20, 30, 40, 80, 20, 80] },
                { name: 'BATCH-5', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>
          )}
          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.light,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.secondary.dark,
              ]}
            />
          </Grid>           */}
{/* 
          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

         

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid> */}          
        </Grid>
      </Container>
    </>
  );
};