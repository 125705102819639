import { useState, useEffect, StrictMode } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';

// @mui
import { Grid, Button, Container, Stack, Typography, Card, TableContainer, Table, TableCell, 
TableRow, TableBody, TablePagination, Paper, Avatar, IconButton, Checkbox, Popover, MenuItem} from '@mui/material';

// components
import Iconify from '../../components/iconify';
import { LabPostCard, LabPostsSort, LabPostsSearch } from  '../../sections/@dashboard/lab';
import Scrollbar from '../../components/scrollbar';
import Label from '../../components/label';

// mock
//import POSTS from '../../_data/blog';

// sections
import { LabListHead, LabListToolbar } from '../../sections/@dashboard/lab/';
import LabDasboard from '../../components/lab/dasboard';

//Lab Datas
import getLab1Vms from '../../_data/labs/lab1/getVms'; //Todo: Bunları tek yerde birleştireceğim node değeri lab ortamını belirliyor. Node adına göre BaseUrl değiştiririm.
import getLab2Vms from '../../_data/labs/lab2/getVms';
import {setStopVm, setStartVm } from '../../_data/labs/lab1/setVms';

// ----------------------------------------------------------------------
const SORT_OPTIONS = [
  { value: 'start', label: 'Start' },
  { value: 'stop', label: 'Stop' },
  { value: 'status', label: 'Status' },
];
const MENU_OPTIONS = [
  { label1: 'Start', },
  { label2: 'Stop', },
  { label3: 'Details', },
];

const TABLE_HEAD = [
  { id: 'vmid', label: 'VmId', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },  
  { id: 'uptime', label: 'Uptime', alignRight: false },  
  { id: 'maxmem', label: 'MaxMem-MB', alignRight: false },  
  { id: 'mem', label: 'Mem. in Use', alignRight: false },  
  { id: 'cpus', label: 'Cpus', alignRight: false },  
  /*{ id: 'cpu', label: 'cpu', alignRight: false },  
  { id: 'maxdisk', label: 'maxdisk', alignRight: false },  
  { id: 'pid', label: 'pid', alignRight: false },  
  { id: 'netin', label: 'maxdisnetink', alignRight: false },  
  { id: 'netout', label: 'netout', alignRight: false },  
  { id: 'diskread', label: 'diskread', alignRight: false },  
  { id: 'diskwrite', label: 'diskwrite', alignRight: false },  
  { id: 'disk', label: 'disk', alignRight: false }, */ 
  { id: '' },
  //vmid, name, status, uptime, mem, maxmem, cpus, cpu, maxdisk, pid, netin, netout, diskread, diskwrite, disk
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {    
    return filter(array, (_vms) => _vms.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);    
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function LabPage() {

  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');  
  const [orderBy, setOrderBy] = useState('vmid');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [vmList, setVmList] = useState([]);
  const filteredVms = applySortFilter(vmList, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredVms.length && !!filterName;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vmList.length) : 0;
  const [open, setOpen] = useState(null);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success')
  const [alertMessage, setAlertMessage] = useState(''); 
  const [nodes, setNodes] = useState(''); 
  //const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);    
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vmList.map((n) => ({ vmid: n.vmid, name: n.name }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const getRowStyle = (statusVms) => {
    if (statusVms === 'stop' ) {
      return { backgroundColor: '#ffcccb' }; // Örneğin, statusVms = stop 0 için açık kırmızı
    } else  if (statusVms === 'running' ) {
      return { backgroundColor: '#90ee90' }; // Örneğin, statusVms = running için açık yeşil
    }
  };

  const handleClick = (event, select) => {
    // Önce selected dizisini kopyalayarak başlıyoruz
    let newSelected = [...selected];  
    // newSelected içinde select.email eşleşmesini arıyoruz
    const selectedIndex = newSelected.findIndex(item => item.vmid === select.vmid);  
    if (selectedIndex === -1) {
      // Eğer bulunamazsa, yeni bir öğe olarak ekliyoruz
      newSelected.push({vmid: select.vmid, name: select.name});
    } else {
      // Eğer bulunursa, o öğeyi newSelected dizisinden çıkarıyoruz
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    fetchVms();
    handleGetNodes();
  }, []);

  const fetchVms = async () => {
    try {
        const data = await getLab1Vms.getVms();
        setVmList(data);
    } catch (error) {
        console.error("Error fetching Vms: ", error);
    }
  };

  const handleGetNodes = async () => { 
    try {
      const NodeInfo = await getLab1Vms.getNodes();
    //  console.log("getNodes: ", NodeInfo);
      setNodes(NodeInfo);
    } catch (error) {
      console.log("Error[handleGetNodes]: ", error);
    }
  };

  const handleDownRecord = async (event) => {
    try {          
      const label = event.currentTarget.textContent;
      if(selected.length > 0){
        switch(label){          
          case 'Start':
            handleSetVmStart('lab', selected[0].vmid);
            break;
          case 'Stop':
          //  console.log("seçilen: ", selected);
            handleSetVmStop('lab', selected[0].vmid);
            break;
          case 'Details':                   
            //handleConfirmDialogOpen(selected, 0);
          //  console.log("selected ", selected);
            break;
          case '-':                   
            //handleConfirmDialogOpen(selected, 1);
            break;
          case '-':                   
            //handleDetailRecordsDialogOpen(selected[0].email);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };

  const handleSetVmStart = (node, vmid) => {
    try {
      setStartVm(node, vmid);
      fetchVms(); //TODO: bu arada beklemek gerebilir mi?
    } catch (error) {
      console.log("handleOpenVmStart error: ", error);
    }
  };

  const handleSetVmStop = (node, vmid) => {
    try {
      setStopVm(node, vmid); //TODO: bu arada beklemek gerebilir mi?
      fetchVms();
    } catch (error) {
      console.log("handleSetVmStop error: ", error);
    }
  };

  return (
    <>
    <StrictMode>
      <Helmet>
        <title>Lab Settings| OAK Academy</title>
      </Helmet>
      <Container>                      
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Oak Academy { nodes.node} VMs Management
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Save
          </Button>
        </Stack>
        <LabDasboard nodes = {nodes}/>
        <Card>
          <LabListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer padding="normal" sx={{ width: '100%', minWidth: 800 }}>
              <Table>
                <LabListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={vmList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredVms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { vmid, name, status, uptime, mem, maxmem, cpus, cpu, maxdisk, pid, netin, netout, diskread, diskwrite, disk, avatarUrl  } = row;
                    const days = Math.floor(uptime / (24 * 3600));
                    const hours = Math.floor((uptime % (24 * 3600)) / 3600);
                    const minutes = Math.floor((uptime % 3600) / 60);
                    const seconds = uptime % 60;
                    const uptimeString = `${days} days ${hours} hours ${minutes} munites ${seconds} seconds`;// TODO: Buradakileri yukarı taşıyabiliriz.

                    const selectedVm = selected.some(item => item.vmid === vmid);                                     
                    return (
                      <TableRow hover key={vmid} tabIndex={-1} role="checkbox" selected={selectedVm}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedVm} onChange={(event) => handleClick(event, {vmid, name})} />                          
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={`${vmid}`} src={avatarUrl}/>
                            <Typography variant="subtitle2" noWrap>
                              {vmid}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" style={{ width: '150px' }}>{name}</TableCell>
                        <TableCell align="left">                          
                          <Label color={status === 'running' ? 'success' : 'error'}>{status === 'running' ? 'Running' : 'Stopped'}</Label>
                        </TableCell>
                        <TableCell align="left" style={{ width: '150px' }}>{uptimeString}</TableCell>                        
                        <TableCell align="left" style={{ width: '150px' }}>{maxmem/1024/1024}</TableCell>  {/* For MB */}
                        <TableCell align="left" style={{ width: '150px' }}>{(mem/1024/1024).toFixed(2)}</TableCell>  {/* For MB */}  
                        <TableCell align="left" style={{ width: '150px' }}>{cpus}</TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50]}
            component="div"
            count={vmList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 240,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleDownRecord} 
          sx={{
              typography: 'body1',
              color: 'red',
              '&:hover': {
              bgcolor: 'mycolor.dark'}, width: 250} } >
          <Iconify icon={'eva:power-outline'} sx={{ mr: 2, color: 'mycolor.main'}} />
          <Typography variant="body1" color="mycolor.main">
            Start
          </Typography>
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label2} onClick={handleDownRecord} sx={{ color: 'prinary.main' }}>
          <Iconify icon={'mdi:power-plug-off-outline'} sx={{ mr: 2 }} />
            Stop
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'secondary.main' }}>
          <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }} />
            Details
        </MenuItem>
      </Popover>
      </StrictMode>
    </>
  );
}
