import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
//import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';

// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow, MenuItem, TableBody,TableCell,
  Container,Typography, IconButton, TableContainer, TablePagination, Snackbar, Alert, Dialog, 
  DialogActions, DialogContent, DialogTitle, TextField, InputAdornment} from '@mui/material';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';

// mysql database
import emailusers from '../../_data/mail/emailusers';
import setStatusEmailUser from '../../_data/mail/setStatusEmailUser';
import setPasswordEmailUser from '../../_data/mail/setPasswordEmailUser';
import CreateEmailDialog from '../../components/mail/create-email-dialog';
import RoutingEmailDialog from '../../components/mail/routing-email-dialog';

//import e from 'cors';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'fullname', label: 'Fullname', alignRight: false },
  { id: 'department', label: 'Department', alignRight: false },
  { id: 'domain_name', label: 'Domain Name', alignRight: false },
  { id: 'status_id', label: 'Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {  
  
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('email');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState(''); 

  const handleClose = () => {
    setMsgOpen(false);
  };

  const hadleDownRecord = async (event) => {
    try {          
      const label = event.currentTarget.textContent;
      if(selected.length > 0){
        switch(label){
          case 'Edit Pass':            
            handleOpenPasswordDialog(selected[0]);
            break;
          case 'Inactive':                   
            handleConfirmDialogOpen(selected, 0);
            break;
          case 'Active':                   
            handleConfirmDialogOpen(selected, 1);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("hadleDownRecord-Error: ", error);
    }
  };
  const MENU_OPTIONS = [
    { label1: 'Edit Password', },
    { label2: 'Inactive', },
    { label3: 'Active', },
  ];

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState({ emails: [], status: null });

  const handleConfirmDialogOpen = (emails, status) => {
    setCurrentAction({emails, status});
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const performAction = async () => {
    const response = await setStatusEmailUser(currentAction.emails, currentAction.status);
    if (response.message === 'Status updated successfully.' && response.affectedRows > 0) {
      setSeverity('success');
      setAlertMessage('Status updated successfully.');
      setMsgOpen(true);
      fetchUsers();
      handleCloseMenu();
    }
  };

  const handleConfirm = () => {
    performAction(currentAction);
    handleConfirmDialogClose();
  };

/**********************************************************************************/

  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  let email = '';
  //const [currentPasswdAction, setCurrentPasswdAction] = useState({ email: '', newPassword: '' });
  
  const handleOpenPasswordDialog = (mail) => { 
    email = mail;
    setIsPasswordDialogOpen(true);
  };

  const handleClosePasswordDialog = () => {
    setIsPasswordDialogOpen(false);
    setNewPassword(''); // New password field reset.
    setConfirmNewPassword('')
  };

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);  
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthMessage, setPasswordStrengthMessage] = useState('');
  const [passwordsMatchMessage, setPasswordsMatchMessage] = useState('');
  useEffect(() => {
    // Enable button if password strength "strong" and passwords match
    const isDisabled = !(passwordStrengthMessage === 'Password is strong' && passwordsMatchMessage === 'Passwords match');
    setIsUpdateButtonDisabled(isDisabled);
  }, [passwordStrengthMessage, passwordsMatchMessage]);

  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[!*?+.%-^$=@]/.test(password)) strength += 1; // Check if there is at least one special character.
    return strength; 
  };

  // const PasswordStrengthBar = ({ strength }) => {
  //   const normalizedStrength = (strength / 6) * 100; // Şifre gücünü 0-100 aralığına dönüştür  
  //   return (
  //     <LinearProgress variant="determinate" value={normalizedStrength} />
  //   );
  // };

  const handleNewPasswordChange = (event) => {
    let newPasswordValue = event.target.value;
    newPasswordValue = newPasswordValue.replace(/[' ]+/g, '');    
    setNewPassword(newPasswordValue);
    const strength = evaluatePasswordStrength(newPasswordValue);
    setPasswordStrength(strength);
    let strengthMessage = '';
    if (strength < 3) {
      strengthMessage = 'Password is very weak';
    } else if (strength <= 4) {
      strengthMessage = 'Password is weak';
    } else if (strength === 5) {
      strengthMessage = 'Password is strong';
    }
    setPasswordStrengthMessage(strengthMessage);    
  };

  const handleSubmitNewPassword = () => {
    setNewPassword(''); // New password field reset.
    setConfirmNewPassword('')
    const MIN_STRENGTH = 5;
    let email = selected[0];
    if (passwordStrength < MIN_STRENGTH) {
      return; 
    }
    if (newPassword === confirmNewPassword) {      
      //setCurrentPasswdAction({email, newPassword});
      passwordPerformAction(email, newPassword);
      setIsPasswordDialogOpen(false);
    } 
  };

  const passwordPerformAction = async (email, newPassword) => {
    const response = await setPasswordEmailUser(email, newPassword);
    if (response.message === 'New password updated successfully.' && response.affectedRows > 0) {
      setSeverity('success');
      setAlertMessage('Password updated successfully.');
      setMsgOpen(true);
      handleCloseMenu();
    }
  };
/**********************************************************************************/
 
const handleConfirmNewPasswordChange = (event) => {
  const confirmNewPasswordValue = event.target.value;
  setConfirmNewPassword(confirmNewPasswordValue);
  let matchMessage = '';
  if (newPassword && confirmNewPasswordValue && newPassword !== confirmNewPasswordValue) {
    matchMessage = 'Passwords do not match';
  } else if (newPassword && confirmNewPasswordValue && newPassword === confirmNewPasswordValue) {
    matchMessage = 'Passwords match';
  }
  setPasswordsMatchMessage(matchMessage);
};
  
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // InputAdornment render functions to add icons to the end of password input fields
  const renderPasswordAdornment = () => (
    <InputAdornment position="end">
      <IconButton onClick={toggleShowPassword} edge="end">
        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
      </IconButton>
    </InputAdornment>
  );

  const renderConfirmPasswordAdornment = () => (
    <InputAdornment position="end">
      <IconButton onClick={toggleShowConfirmPassword} edge="end">
        <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
      </IconButton>
    </InputAdornment>
  );

 


  const [userList, setUserList] = useState([]);
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await emailusers();
      setUserList(data);
    } catch (error) {
        console.error("Error fetching users: ", error);
    }
  };
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;
  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;
  return (
    <>
      <Helmet>
        <title> EMail Users | Oak Academy </title>
      </Helmet>

      <Container sx={{ minWidth: 800, padding: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Oak Users eMails
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
           New EMail
          </Button> */}
          <RoutingEmailDialog />
          <CreateEmailDialog />
        </Stack>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer padding="normal" sx={{ width: '100%', minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { avatarUrl, email, fullname,  department, domain_name, status_id  } = row;
                    const selectedUser = selected.indexOf(email) !== -1;
                    return (
                      <TableRow hover key={email} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, email)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={email} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {email}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{fullname}</TableCell>
                        <TableCell align="left">{department}</TableCell>                        
                        <TableCell align="left">{domain_name}</TableCell>
                        <TableCell align="left">
                          <Label color={(status_id === 1 && 'success') || (status_id === 0 && 'error') || 'default'}>
                            {status_id === 1 ? 'Active' : 'Inactive'}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label1} onClick={hadleDownRecord}>
          <Iconify icon={'eva:edit-2-fill'} sx={{ mr: 2 }} />
            Edit Pass
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label2} onClick={hadleDownRecord} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:slash-outline'} sx={{ mr: 2 }} />
            Inactive
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label3} onClick={hadleDownRecord} sx={{ color: 'success.dark' }}>
          <Iconify icon={'eva:undo-fill'} sx={{ mr: 2 }} />
            Active
        </MenuItem>
      </Popover>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={msgOpen}  autoHideDuration={8000} onClose={handleClose}>          
        <Alert severity={severity} onClose={handleClose} 
          sx={{ 
            width: 450, 
            minWidth: 288, // Min genişliği ayarlayabilirsiniz, isteğe bağlı
            fontSize: 14, 
            //fontWeight: 'bold', 
            fontStyle: 'normal' 
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected.map((email, index) => (
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {email}
            </p>
          ))}
          <p>
            The above e-mail addresses will be set as{' '}
            <span style={{ fontWeight: 'bold', color: currentAction.status === 0 ? 'red' : 'green' }}>
              {currentAction.status === 0 ? 'Inactive' : 'Active'}
            </span>.
            Are you sure you want to do this?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus
            style={{
              backgroundColor: currentAction.status === 0 ? 'red' : 'green',
              color: '#FFFFFF',
            }}
          >
            OK
          </Button>
        </DialogActions>        
      </Dialog>
      <Dialog open={isPasswordDialogOpen} onClose={handleClosePasswordDialog}>
        <DialogTitle>Set New Password For <span style={{ color: 'red' }}>"{selected[0]}"</span></DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={handleNewPasswordChange}
            InputProps={{
              endAdornment: renderPasswordAdornment(),
            }}
          />
          <Typography 
            color={passwordStrength >= 2 ? "primary" : "error"} 
            variant="caption"
          >
            {passwordStrengthMessage}             
          </Typography>
          <TextField
            margin="normal"
            label="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            value={confirmNewPassword}
            onChange={handleConfirmNewPasswordChange}
            InputProps={{
              endAdornment: renderConfirmPasswordAdornment(),
            }}
          />          
          <Typography 
            color={passwordsMatchMessage === 'Passwords match' ? "green" : "red"} 
            variant="caption"
          >
            {passwordsMatchMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePasswordDialog}>Cancel</Button>
          <Button style={{
              backgroundColor: 'blue',
              color: '#FFFFFF',
            }} onClick={handleSubmitNewPassword}
            disabled={isUpdateButtonDisabled} // Butonun disabled özelliğini bağla
          >Update Password</Button>
        </DialogActions>
      </Dialog>      
    </>
  );
}
