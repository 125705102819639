import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getLmsCourses = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/lms/allcourses`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const meta = response.data.meta;
      const info = response.data.courses || [];
      const lmsCoursesInfo = info.map((item, index) => {
        return {
          id: item.id || '',
          name: item.name || '',
          heading: item.heading || '',
          is_published: item.is_published || '',
          description: item.description || '',
          image_url: item.image_url || '',
        };
      });
      return {lmsCoursesInfo, meta};
    } catch (error) {
      return [];
    }
};
export default getLmsCourses;
